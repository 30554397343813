// フラッシュメッセージの表示を3秒で消す
$(function () {
  setTimeout("$('.js-flash').fadeOut()", 3000)
})

// SP Drawer
$(function () {
  $('.js-drawer_btn').click(function () {
    $(this).toggleClass('active')
    $('.p-drawer_bg').fadeToggle()
    $('.p-drawer_nav').toggleClass('open')
    $('.js-drawer_search').fadeToggle()
  })
  $('.p-drawer_bg').click(function () {
    $(this).fadeOut()
    $('.js-drawer_btn').removeClass('active')
    $('html, body').removeAttr('style')
    $('.p-drawer_nav').removeClass('open')
    $('.js-drawer_search').fadeToggle()
  })
  $('.p-drawer_nav_main a').click(function () {
    $('.p-drawer_bg').fadeOut()
    $('.js-drawer_btn').removeClass('active')
    $('html, body').removeAttr('style')
    $('.p-drawer_nav').removeClass('open')
    $('.js-drawer_search').fadeToggle()
  })
})

// SP 検索ボタン
$(function () {
  $('.js-drawer_search').click(function () {
    $('.p-drawer_top_search').fadeToggle()
  })
})

// SP Footer
$(function () {
  if (window.matchMedia('(max-width: 768px)').matches) {
    $('.p-footer_right_bottom_content_list').hide()
    $('.js-open').click(function () {
      $(this).next('.p-footer_right_bottom_content_list').slideToggle()
      $(this).toggleClass('open')
    })
  }
})

// マイページ いいねした商品 カレント表示
$(function () {
  $('.js-tab .target').each(function () {
    var targetUrl = $(this).data('link')
    var currentUrl = location.search
    if (currentUrl.match(targetUrl)) {
      $(this).addClass('current')
    } else if (currentUrl == "" && targetUrl == "all") {
      $(this).addClass('current')
    } else {
      $(this).removeClass('current')
    }
  })
})

// マイページ 商品一覧 aタグ要素中
$(function () {
  $('.js-itemsMypageEdit').each(function () {
    $(this).on('click', function () {
      var href = $(this).prop('href')
      window.open(href, '_self')
      return false
    })
  })
})

// トップ カテゴリー 連番クラス付与
$(function () {
  $('.js-category-icon').each(function (i) {
    $(this).addClass('fc-' + (i + 1))
  })
  $('.js-category-img').each(function (i) {
    $(this).addClass('fc-' + (i + 1))
  })
})
